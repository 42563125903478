
import {IonPage, IonContent, IonHeader, IonToolbar, IonItem, IonInput, IonSelect ,
IonCard, IonCardContent} from '@ionic/vue';
import { useVuelidate } from "@vuelidate/core";
import {email, maxLength, minLength, required} from "@vuelidate/validators";
import profileHelper from "@/helpers/ImageHelper";



export default {
  name:'ProfileModal',
  props:{
      user: Object,
  },
  components:{
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonItem,
    IonInput,
    IonSelect,
    IonCard,
    IonCardContent
  },
  setup(){
    return {
      v: useVuelidate()
    }
  },
  methods: {
    takePhoto(){
      return this.$store.dispatch('profile/takePhoto');
    },
    save() {
      this.$emit("modal-save");
    },
    cancel() {
      this.$emit("modal-cancel");
    }
  },
  computed:{
    profileImage(){
      return profileHelper.profileImage(this.user);
    }
  },
  data() {
    return {
      success: false,
      loading: false,
      errorUserExists: false,
      errorEmailExists: false,
      error: false
    }
  },
  validations(){
    return{
      user: {
        email: {
          required,
          email
        },
        firstName: {
          required,
          minlength: minLength(1),
          maxlength: maxLength(254)
        },
        lastName:{
          minlength: minLength(1),
          maxlength: maxLength(254)
        },
        username: {
          // required,
          // minlength: minLength(2),
          // maxlength: maxLength(50),

        },
        status:{

        }
      }

    }
  },
}
