
import { IonSegment, IonSegmentButton, IonList, IonLabel, IonListHeader } from '@ionic/vue';
import BaseLayout from "@/components/BaseLayout.vue";
import EventCard from "@/components/card/EventCard.vue";
import ActivityCard from "@/components/card/ActivityCard.vue";
import GroupCard from "@/components/card/GroupCard.vue";
import ProfileCard from "@/components/card/ProfileCard.vue";
import missingCard from "@/components/card/MissingCard.vue";
import {mapGetters} from "vuex";

export default  {
  name: 'Profile',

  components: {
    GroupCard,
    BaseLayout,
     IonSegment,
    IonSegmentButton,
    IonLabel,
    IonListHeader,
    IonList,
    EventCard,
    ActivityCard,
    ProfileCard,
    missingCard
  },
  ionViewWillEnter() {

    this.loadProfile(this.$route.params.id);
  },
  data(){
    return{
      active:'activities' as string,
    }
  },
  computed:{
    ...mapGetters('profile',[
      'profile',
      'hasActivities',
      'hasEvents',
      'hasGroups'
    ]),
    backit(){
      return this.$route.params.id;
    }
  },
  methods: {
    segmentChanged(ev: CustomEvent) {
      this.active = ev.detail.value;
    },
    loadProfile(profileId){
      // this.$store.dispatch('account');
      return this.$store.dispatch('profile/profile', profileId);
    },
  },
}
