
    import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonLabel, IonChip, IonModal, IonIcon, IonButton} from '@ionic/vue';
    import {lockClosedOutline,createOutline,cameraOutline, camera} from 'ionicons/icons';
    import ProfileModal from "@/components/modal/ProfileModal.vue";
    import profileHelper from "@/helpers/ImageHelper";

    export default {
        name: 'ProfileCard',
        props:{
           person: Object,
        },
        components:{
          IonCard,
          IonCardHeader,
          IonCardTitle,
          IonCardSubtitle,
          IonIcon,
          IonChip,
          IonModal,
          ProfileModal,
          IonButton,
          IonLabel
        },

      setup(){
          return{
            lockClosedOutline,
            createOutline,
            cameraOutline,
            camera
          }
      },
      methods:{
        openModal() {
          this.modalIsOpen = true;
        },
        handleSave() {
          this.$store.dispatch('profile/update',{athlete: this.person});
          this.modalIsOpen = false;
          this.toastInfo = {
            isOpen: true,
            message: "Modal Was Closed with OK"
          };
        },
        handleCancel() {
          this.modalIsOpen = false;
        },
        takePhoto(){
          return this.$store.dispatch('profile/takePhoto');
        },
      },
      computed:{
          hasName(){
            return (this.person.firstName || this.person.lastName);
          },
         canEdit(){
            return this.$store.getters['profile/canEdit'];
         },
        profileImage(){
            return profileHelper.profileImage(this.person)
        }
      },
      data() {
        return {
          modalIsOpen: false,
          toastInfo: {
            isOpen: false,
            message: ""
          },
        };
      }
    }
